<template>
  <b-modal
    id="modal-informe-cirugia"
    size="custom-lg-max"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header>
      <h5 class="modal-title">
        <b>Cirugia N° 1</b>
      </h5>
    </template>
    <b-tabs content-class="px-0 pb-0">
      <b-tab
        title="Parte 1"
        active
      >
        <div class="row mb-3">
          <div class="col-sm-6">
            <div class="row mx-0">
              <div class="col-sm-2 pl-0 text-right">
                <label class="col-form-label">
                  Fecha:
                </label>
              </div>
              <div class="col-sm-4 px-0">
                <date-picker
                  class="w-100"
                  v-model="tabParte1.fecha"
                  lang="es"
                  format="DD/MM/YYYY"
                  type="date"
                  value-type="date"
                ></date-picker>
              </div>
              <div class="col-sm-3 pl-2 text-right">
                <label class="col-form-label">
                  Hora Inicio:
                </label>
              </div>
              <div class="col-sm-3 px-0">
                <input type="time" class="form-control">
              </div>
            </div>
          </div>
          <div class="col-sm-6 px-1">
            <div class="row mx-0">
              <div class="col-sm-4 pl-0 text-right">
                <label class="col-form-label">
                  Hora Conclusión:
                </label>
              </div>
              <div class="col-sm-3 pl-0 pr-2">
                <input type="time" class="form-control">
              </div>
              <div class="col-sm-2 pl-0 pr-2 text-right">
                <label class="col-form-label">
                  Duración:
                </label>
              </div>
              <div class="col-sm-3 pl-0 pr-2">
                <input type="text" class="form-control px-1 text-center" disabled value="2: 30 Horas">
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-sm-9 pr-0">
            <div class="row mx-0">
              <div class="col-sm-2 px-0 text-center">
                <label class="col-form-label">
                  Recuperación:
                </label>
              </div>
              <div class="col-sm-10 pl-2 pr-0">
                <input type="text" class="form-control">
              </div>
            </div>
          </div>
          <div class="col-sm-3 px-1">
            <div class="row mx-0">
              <div class="col-sm-4 pl-2 pr-0 text-right">
                <label class="col-form-label">
                  Tiempo:
                </label>
              </div>
              <div class="col-sm-8 px-2">
                <input type="text" class="form-control">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <b>Diagnóstico</b>
          </div>
        </div>
        <div class="row mb-3 mx-0">
          <div class="col-sm-12 custom-bg-gray py-3 pr-2">
            <div class="row mx-0">
              <div class="col-sm-2 text-right">
                <label class="col-form-label">Al ingresar:</label>
              </div>
              <div class="col-sm-4 px-0">
                <input type="text" class="form-control">
              </div>
              <div class="col-sm-2 pl-0 text-right">
                <label class="col-form-label">Al Salir:</label>
              </div>
              <div class="col-sm-4 px-0">
                <input type="text" class="form-control">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <b>Funcionamiento de Equipos</b>
          </div>
        </div>
        <div class="row mb-3 mx-0">
          <div class="col-sm-12 custom-bg-gray py-3 px-0">
            <div class="row mx-0 mb-2">
              <div class="col-sm-1 pl-0 text-right">
                <label class="col-form-label">Cialitica:</label>
              </div>
              <div class="col-sm-2 px-0">
                <input type="text" class="form-control">
              </div>
              <div class="col-sm-2 pl-0 text-right">
                <label class="col-form-label">Electro-Biopolar:</label>
              </div>
              <div class="col-sm-2 px-0">
                <input type="text" class="form-control">
              </div>
              <div class="col-sm-2 px-0 text-right">
                <label class="col-form-label">Maquina de anestesia:</label>
              </div>
              <div class="col-sm-3 pr-5">
                <input type="text" class="form-control">
              </div>
            </div>
            <div class="row mx-0 mb-2">
              <div class="col-sm-1 pl-0 text-right">
                <label class="col-form-label">Oxigeno:</label>
              </div>
              <div class="col-sm-2 px-0">
                <input type="text" class="form-control">
              </div>
              <div class="col-sm-2 pl-0 text-right">
                <label class="col-form-label">Electro-Monopolar:</label>
              </div>
              <div class="col-sm-2 px-0">
                <input type="text" class="form-control">
              </div>
              <div class="col-sm-2 px-0 text-right">
                <label class="col-form-label">Equipo de aspiración:</label>
              </div>
              <div class="col-sm-3 pr-5">
                <input type="text" class="form-control">
              </div>
            </div>
            <div class="row mx-0 mb-2">
              <div class="col-sm-1 pl-0 text-right">
                <label class="col-form-label">Monitor:</label>
              </div>
              <div class="col-sm-2 px-0">
                <input type="text" class="form-control">
              </div>
              <div class="col-sm-2 pl-0 text-right">
                <label class="col-form-label">Gas Carbonico (CO2):</label>
              </div>
              <div class="col-sm-2 px-0">
                <input type="text" class="form-control">
              </div>
            </div>
            <div class="row mx-0 mb-2">
              <div class="col-sm-12">
                Observación
              </div>
            </div>
            <div class="row mx-0 mb-2">
              <div class="col-sm-12">
                <input type="text" class="form-control border-dashed">
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="offset-sm-6 col-sm-6 text-right">
            <button class="btn btn-light mr-2" @click="cancelarModal">Cancelar</button>
            <button class="btn btn-success" @click="guardarModal">Guardar</button>
          </div>
        </div>
      </b-tab>

      <b-tab
        title="Parte 2"
      >
        <div class="row mb-3">
          <div class="col-sm-5">
            <h5 class="mb-0"><b>TRANS OPERATORIO</b></h5>
          </div>
        </div>
        <div class="row mb-3 mx-0">
          <div class="col-sm-12 custom-bg-gray py-2">
            <div class="row">
              <div class="col-sm-2">
                <label class="font-weight-bold col-form-label">Tipo de Anestesia</label>
              </div>
              <div class="col-sm-2">
                <input type="checkbox" class="align-middle">
                <label class="col-form-label ml-2">
                  General
                </label>
              </div>
              <div class="col-sm-2">
                <input type="checkbox" class="align-middle">
                <label class="col-form-label ml-2">
                  Sedación
                </label>
              </div>
              <div class="col-sm-2">
                <input type="checkbox" class="align-middle">
                <label class="col-form-label ml-2">
                  Raquídea
                </label>
              </div>
              <div class="col-sm-2">
                <input type="checkbox" class="align-middle">
                <label class="col-form-label ml-2">
                  Bloqueo
                </label>
              </div>
              <div class="col-sm-2">
                <input type="checkbox" class="align-middle">
                <label class="col-form-label ml-2">
                  Cal
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mx-0">
          <div class="col-sm-7">
            <p class="mb-0">
              CIRUJANO ANESTESIOLOGO Y ENFERMERO(A) CONFIRMAN VERBALMENTE
              IDENTIDAD DEL PACIENTE, SITIO QUIRURGICO, PROCEDIMIENTO A REALIZAR.
            </p>
          </div>
          <div class="offset-sm-1 col-sm-2 pr-0 d-flex">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
              <label class="form-check-label h5">
                <b>Si</b>
              </label>
            </div>
          </div>
          <div class="col-sm-2 d-flex pl-0">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
              <label class="form-check-label h5">
                <b>No</b>
              </label>
            </div>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col-sm-12 custom-bg-gray py-2">
            <div class="row">
              <div class="col-sm-4 pr-0">
                <label class="col-form-label">
                  Se ha administrado Profilaxis durante la cirugia:
                </label>
              </div>
              <div class="col-sm-8">
                <input type="text" class="form-control border-dashed">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <label class="font-weight-bold col-form-label">
                  Material al inicio de la Cirugía
                </label>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-sm-1 pr-0 text-right">
                <label class="col-form-label">Gasa N°:</label>
              </div>
              <div class="col-sm-3">
                <input type="text" class="form-control border-dashed">
              </div>
              <div class="col-sm-2 pr-0 text-right">
                <label class="col-form-label">Compresas N°:</label>
              </div>
              <div class="col-sm-3">
                <input type="text" class="form-control border-dashed">
              </div>
              <div class="col-sm-3 pl-0">
                <div class="row">
                  <div class="col-sm-3 text-right">
                    <label class="col-form-label">
                      Otro:
                    </label>
                  </div>
                  <div class="col-sm-9 pl-0">
                    <input type="text" class="form-control border-dashed">
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-sm-7">
                <p class="mb-0">
                  CIRUJANO ANESTESIOLOGO Y ENFERMERO(A) CONFIRMAN VERBALMENTE
                  IDENTIDAD DEL PACIENTE, SITIO QUIRURGICO, PROCEDIMIENTO A REALIZAR.
                </p>
              </div>
              <div class="offset-sm-1 col-sm-2 pr-0 d-flex">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                  <label class="form-check-label h5">
                    <b>Si</b>
                  </label>
                </div>
              </div>
              <div class="col-sm-2 d-flex pl-0">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" style="width: 16px;height: 16px">
                  <label class="form-check-label h5">
                    <b>No</b>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="offset-sm-6 col-sm-6 text-right">
            <button class="btn btn-light mr-2" @click="cancelarModal">Cancelar</button>
            <button class="btn btn-success" @click="guardarModal">Guardar</button>
          </div>
        </div>
      </b-tab>

      <b-tab
        title="Parte 3"
      >
        <div class="row mb-2">
          <div class="col-sm-5">
            <h5 class="mb-0"><b>SALIDA POST QUIRURGICO</b></h5>
          </div>
        </div>
        <div class="row mx-0 mb-3">
          <div class="col-sm-12 custom-bg-gray py-2">
            <div class="row mb-2">
              <div class="col-sm-12">
                <b>El enfermero(a) confirma verbalmente con el equipo y recuento:</b>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-2 text-right">
                <label class="col-form-label">De los instrumentos:</label>
              </div>
              <div class="col-sm-10 pl-0">
                <input type="text" class="form-control border-dashed">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-1 pr-0">
                <label class="col-form-label">Gasa N°:</label>
              </div>
              <div class="col-sm-2 pl-0">
                <input type="text" class="form-control border-dashed">
              </div>
              <div class="col-sm-1 px-0 text-nowrap">
                <label class="col-form-label"
                >Compresas N°:</label>
              </div>
              <div class="col-sm-2 pl-3 pr-1">
                <input type="text" class="form-control border-dashed">
              </div>
              <div class="col-sm-1 px-0 text-right">
                <label class="col-form-label">Apositos N°:</label>
              </div>
              <div class="col-sm-2 pr-0">
                <input type="text" class="form-control border-dashed">
              </div>
              <div class="col-sm-1 text-right">
                <label class="col-form-label">Otros:</label>
              </div>
              <div class="col-sm-2 pl-0">
                <input type="text" class="form-control border-dashed">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 pr-0">
                <label class="col-form-label text-nowrap">
                  Todo el Equipo Quirurgico Concluye la Cirugía a las:
                </label>
              </div>
              <div class="col-sm-2 px-4">
                <input type="time" class="form-control text-center">
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-12">
                <b>Se etiqueta y prepara, muestras y piezas quirurgicas según orden:</b>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-2 text-right">
                <label class="col-form-label">Con Drenajes:</label>
              </div>
              <div class="col-sm-2 px-0">
                <input type="text" class="form-control border-dashed">
              </div>
              <div class="col-sm-1 pr-0 text-right">
                <label class="col-form-label">
                  Cantidad
                </label>
              </div>
              <div class="col-sm-3">
                <input type="text" class="form-control border-dashed">
              </div>
              <div class="col-sm-1 pr-0 text-right">
                <label class="col-form-label">
                  Ubicación:
                </label>
              </div>
              <div class="col-sm-3">
                <input type="text" class="form-control border-dashed">
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-5">
            <h5 class="mb-0"><b>PIEZA QUIRURGICA, MUESTRAS Y BIOPSIAS</b></h5>
          </div>
        </div>
        <div class="row mx-0 mb-2">
          <div class="col-sm-2 text-right">
            <label class="col-form-label">
              Pieza Quirurgica:
            </label>
          </div>
          <div class="col-sm-2 px-0">
            <input type="text" class="form-control border-dashed" placeholder="Cantidad">
          </div>
          <div class="col-sm-8">
            <input type="text" class="form-control border-dashed" placeholder="Descripción">
          </div>
        </div>
        <div class="row mx-0 mb-2">
          <div class="col-sm-2 text-right">
            <label class="col-form-label">
              Biopsias:
            </label>
          </div>
          <div class="col-sm-2 px-0">
            <input type="text" class="form-control border-dashed" placeholder="Cantidad">
          </div>
          <div class="col-sm-8">
            <input type="text" class="form-control border-dashed" placeholder="Descripción">
          </div>
        </div>
        <div class="row mx-0 mb-2">
          <div class="col-sm-2 text-right">
            <label class="col-form-label">
              Cultivo:
            </label>
          </div>
          <div class="col-sm-2 px-0">
            <input type="text" class="form-control border-dashed" placeholder="Cantidad">
          </div>
          <div class="col-sm-8">
            <input type="text" class="form-control border-dashed" placeholder="Descripción">
          </div>
        </div>
        <div class="row mx-0">
          <div class="col-sm-2 text-right">
            <label class="col-form-label">
              Citoquimicos:
            </label>
          </div>
          <div class="col-sm-2 px-0">
            <input type="text" class="form-control border-dashed" placeholder="Cantidad">
          </div>
          <div class="col-sm-8">
            <input type="text" class="form-control border-dashed" placeholder="Descripción">
          </div>
        </div>
        <div class="row mt-4">
          <div class="offset-sm-6 col-sm-6 text-right">
            <button class="btn btn-light mr-2" @click="cancelarModal">Cancelar</button>
            <button class="btn btn-success" @click="guardarModal">Guardar</button>
          </div>
        </div>
      </b-tab>

      <b-tab
        title="Parte 4"
      >
        <div class="row mx-0 mb-2">
          <div class="col-sm-12">
            CONDICIONES PAQUETE QUIRURGICO EXTERNO:
          </div>
          <div class="col-sm-12">
            <textarea
              class="form-control resize-none"
              rows="4"
            ></textarea>
          </div>
        </div>
        <div class="row mx-0 mb-2">
          <div class="col-sm-12">
            CONDICIONES PAQUETE QUIRURGICO INTERNO:
          </div>
          <div class="col-sm-12">
            <textarea
              class="form-control resize-none"
              rows="4"
            ></textarea>
          </div>
        </div>
        <div class="row mx-0 mb-2">
          <div class="col-sm-12">
            CONDICIONES DE LA CAJA INSTRUMENTAL:
          </div>
          <div class="col-sm-12">
            <textarea
              class="form-control resize-none"
              rows="4"
            ></textarea>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col-sm-12">
            ETIQUETAS DEL MATERIAL QUIRURGICO DE OSTEOSINTESIS-HEMODERIVADOS:
          </div>
          <div class="col-sm-12">
            <textarea
              class="form-control resize-none"
              rows="4"
            ></textarea>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-6 pl-5">
            <span
              class="col-form-label text-secondary font-weight-bold cursor-pointer d-inline-block"
            >
              Imprimir Informe
            </span>
          </div>
          <div class="col-sm-6 text-right">
            <button class="btn btn-light mr-2" @click="cancelarModal">Cancelar</button>
            <button class="btn btn-success" @click="guardarModal">Guardar</button>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
  name: 'ModalInformeCirugia',
  components: {
    DatePicker,
  },
  data() {
    return {
      tabParte1: {
        fecha: null,
      },
    };
  },
  methods: {
    cancelarModal() {
      this.$bvModal.hide('modal-informe-cirugia');
    },
    guardarModal() {
      this.$bvModal.hide('modal-informe-cirugia');
    },
  },
};
</script>

<style scoped>
.custom-bg-gray {
  background: #eee;
}
.border-dashed{
  border-style: dashed;
  border-width: 1px;
  border-color: gray;
}
</style>
