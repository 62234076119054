<template>
  <div class="col-sm-12">
    <div class="row">
      <div class="col-sm-2"
        v-for="(indicacion, index) in indicacionsMedicas" :key="index"
      >
        <b-card
          class="text-center border custom-height"
          :class="getClassCard"
          :header-class="'py-1 '+getBorderClass"
          @click="selectItem(indicacion)"
          :body-class="isNormalBox ? 'px-2 body-card-fill cursor-pointer':'px-2 pb-0 pt-2 cursor-pointer'"
        >
          <template #header v-if="isAprobadoOrPendiente">
            <b v-if="isAprobado">
              Aprobado
            </b>
            <b v-else>Pendiente</b>
          </template>
          <div class="row py-4">
            <div
              class="col-sm-12"
              :class="mainTextClass"
            >
              <b>{{mainText}}</b>
            </div>
            <div
              class="col-sm-12"
              :class="medicoNameClass"
            >
              {{ indicacion.medico }}
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-sm-12">
              {{ indicacion.fecha }} - H: {{ indicacion.hora }}
            </div>
          </div>
        </b-card>
      </div>
    <TheModalMedicalIndicationEnfermeria/>
  </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TheModalMedicalIndicationEnfermeria from './TheModalMedicalIndication.vue';

export default {
  name: 'InfoEnfermeriaItem',
  props: {
    mainText: {
      type: String,
      default: '',
    },
    mainTextClass: {
      type: String,
      default: '',
    },
    medicoNameClass: {
      type: String,
      default: '',
    },
    backgroundClass: {
      type: String,
      default: '',
    },
    typeItem: {
      type: Number,
      default: 1,
      validator(value) {
        // 1: normal, 2: aprobado, 3: pendiente
        return [1, 2, 3].includes(parseInt(value, 10));
      },
    },
  },
  data() {
    return {
    };
  },
  methods: {
    async selectItem(item) {
      const origen = 'enfermeria';
      const RESULT_OK = await this.getIndicacionMedica({ id: item.id, origen });
      if (RESULT_OK) {
        this.$bvModal.show('modal-indicacionMedica');
      }
    },
    ...mapActions('theModalMedicalIndication', [
      'resetTheModalIndicacionMedica',
      'getIndicacionMedica',
    ]),
  },
  computed: {
    getClassCard() {
      return `${this.getBorderClass} ${this.backgroundClass}`;
    },
    getBorderClass() {
      let result = '';
      switch (this.typeItem) {
        case 1:
          result = 'border-light';
          break;
        case 2:
          result = 'border-success';
          break;
        case 3:
          result = 'border-warning';
          break;
        default:
          break;
      }
      return result;
    },
    isNormalBox() {
      if (this.typeItem === 1) {
        return true;
      }
      return false;
    },
    isAprobado() {
      if (this.typeItem === 2) {
        return true;
      }
      return false;
    },
    isPendiente() {
      if (this.typeItem === 3) {
        return true;
      }
      return false;
    },
    isAprobadoOrPendiente() {
      if (this.typeItem === 2 || this.typeItem === 3) {
        return true;
      }
      return false;
    },
    ...mapState('atencionEnfermeria', [
      'indicacionsMedicas',
    ]),
  },
  beforeDestroy() {
    this.resetTheModalIndicacionMedica();
  },
  components: {
    TheModalMedicalIndicationEnfermeria,
  },
};
</script>

<style lang="scss" scoped>
$px-fill: 29.81px;
$pt-2: 8px;

.custom-height {
  height: 160px;
}
.body-card-fill {
  padding-top: $pt-2+$px-fill;
  padding-bottom: 0px;
}
</style>
